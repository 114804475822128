html, body, #root { height:100%; }
body{ font-family: 'Play', sans-serif !important; background-color:#111111 !important; width:100%; }

h1, h2, h3, p{ color:white; }
h1{ font-size:25px; }
button{ cursor:pointer; }
button.default{ background:#3450C6; color:white; font-size:21px; border:0; border-radius: 10px; font-weight: 600; padding:10px 60px; margin-top:10px; }
button.logout{ background:#A52626; color:white; font-size:21px; border:0; border-radius: 10px; font-weight: 600; padding:10px 60px; margin-top:10px; }


.main_login{ border: 1px solid #222222;  border-radius:10px; width:100%; height:100%; margin:0; display: flex; flex-direction: column; }
.main{ background-color:black !important; border: 1px solid #222222;  border-radius:10px; width:935px; margin-top:30px; margin:0 auto; padding-bottom:60px; }

@media only screen and (max-width: 934px) {
    .main_login{ border: 1px solid #222222;  border-radius:10px; width:100%; height:100%; margin:0; display: flex; flex-direction: column; }
    .main{ border: 1px solid #222222;  border-radius:10px; width:100%; height:100%; margin:0; display: flex; flex-direction: column; }

}

::-webkit-scrollbar{ width: 6px; height: 0px; }
::-webkit-scrollbar-thumb{ background: #2BF799; border-radius: 0px; }
::-webkit-scrollbar-thumb:hover{ background: #3450C6; }
::-webkit-scrollbar-track{ background: #F0F0F0; border-radius: 0px; box-shadow: inset 0px 0px 0px 0px #F0F0F0; }

.login{ background:url('./assets/login.jpg') no-repeat; background-size: cover; background-position: right; display: flex; flex-direction: column; align-items: center; justify-content: center; height:100%; margin: 0 auto; width:100%; }
  .login .logo{ max-width:263px; margin-bottom: 30px; }
  .login input{ color:white; border:0; height:50px; width:90%; border-radius: 5px; background:#18181C; margin:5px; text-align: center;  width:375px;  }
  .login button{ background:#3450C6; color:white; font-size:21px; border:0; border-radius: 10px; font-weight: 600; padding:10px 60px; margin-top:10px; }

  @media only screen and (max-width: 934px) {
    .login input{ color:white; border:0; height:50px; width:90%; border-radius: 5px; background:#18181C; margin:5px; text-align: center;  width:80%;  }
  }

  .slick-dots{ bottom:20px !important; }
  .slick-dots li button{ color:white !important; width: 40px !important; height:40px !important; }
  .slick-dots li button:before{ color:white !important; width: 40px !important; height:40px !important; font-size:13px !important; }

.intro_slider{ position:relative; height:100%; width: 100%; }
  .intro_slider div{  height:100%; width:100%; overflow: hidden; position:relative; }
    .intro_slider div .content{ display:flex; flex-direction: column; align-items: center; justify-content: center; width:400px; height:100%; margin:0 auto; }

    @media only screen and (max-width: 934px) {
      .intro_slider div .content{ display:flex; flex-direction: column; align-items: center; justify-content: center; width:100%; height:100%; margin:0 auto; }
    }

    .intro_slider div .logo{ width:111px; margin:0 auto; display: block; margin-bottom:30px; }
    .intro_slider div p{ font-size: 14px; padding:0 20px; text-align:center; }
    .intro_slider div .intro_bg{ position:absolute; top:0; z-index:-1; width: 100%; }
    @media only screen and (max-width: 1024px) {
        .intro_slider div .intro_bg{ width:auto; height:100%; left:-200px; }
    }

.cadastro{ background:url("./assets/bg_cadastro.jpg") no-repeat; background-size: cover; background-position: center; display: flex; height:100%; align-items: center; justify-content: center; text-align:center; flex-direction: column; width: 100%; }
  .cadastro .parte1, .cadastro .parte2, .cadastro .parte3{ display: flex; height:100%; align-items: center; justify-content: center; text-align:center; flex-direction: column; width: 100%; }
  .cadastro .content{ width:700px; text-align: center; }
  @media only screen and (max-width: 1024px) {
    .cadastro .content{ width:100%; text-align: center; }
  }
  .cadastro .check{ display:flex; align-items: center; justify-content: center; }
  .cadastro .check p{ margin:0; }
  

  .cadastro h1{ border:2px solid #3450C6; border-radius: 10px; padding:10px 15px; width: 90%; box-sizing: border-box; margin:15px auto; font-size:20px; font-weight: bold; }
  .cadastro fieldset{ border:0; margin:0; }
    .cadastro fieldset p{ text-align:left; }
  .cadastro label{ color:white; text-align: center; width:100%; display: block; font-size:14px; }
  .cadastro select{ color:white; border:0; height:50px; width:90%; border-radius: 10px; background:rgba(0,0,0,0.6); margin:5px; text-align: center; padding:0 10px; box-sizing: border-box; }
  .cadastro input{ color:white; border:0; height:50px; width:90%; border-radius: 10px; background:rgba(0,0,0,0.6); margin:5px; text-align: center; padding:0 10px; box-sizing: border-box; }
  input[type=checkbox] { width: 20px; height:20px;  }
  .cadastro button.default{ background:#3450C6; color:white; font-size:21px; border:0; border-radius: 10px; font-weight: 600; padding:10px 60px; margin-top:10px; }

  .cadastro .paginador{ display: flex; align-items:flex-end; justify-content: space-around; margin-bottom:20px; width:90%; max-width: 337px; }
    .cadastro .paginador div{ border:2px solid white; border-radius:100px; color:white; display:flex; font-size:25px; 
                              opacity:0.4; padding:4px 17px; height:52px; width: 52px; box-sizing: border-box; position:relative; }
      .cadastro .paginador div.ativo{ opacity:1;  }
      .cadastro .paginador div.risco::before { content:""; position: absolute; bottom: 0; left: 56px; right: 0; top: 25px; height: 0.5em; width: 100%; border-top: 1px solid white;  z-index: 2; }
  
      .cadastro .avatar{ background:url("./assets/avatar_circle.png") no-repeat; background-position: center;  }
      .cadastro .avatar div{ margin:0 auto; width:190px; }
      .cadastro .avatar .slick-next{ right:-45px; }
      .cadastro .avatar .slick-next:before{ content:""; background-image: url("./assets/right.png"); height:34px; width:19px; display:block; }
      .cadastro .avatar .slick-prev{ left:-45px; z-index:10; }
      .cadastro .avatar .slick-prev:before{ content:""; background-image: url("./assets/left.png"); height:34px; width:19px; display:block; }
  

  .invade_top{ margin-top:-5px !important}
  .margin-top-10{ margin-top:10px; }

  header{ display:flex; padding:10px 0; align-items: center; justify-content: center; }

  .dashboard{ display:flex; position:relative; align-items: center; flex-direction: column; height:100%; overflow-y: auto; box-sizing: border-box; padding-bottom:10px; }


    .dashboard .avatar{ position:relative; z-index:0; top:0; left:0; height: 200px; width: 100%; }
      .dashboard .avatar .bg_mask{ position:absolute; top:0; z-index:2; margin:0 auto; display: block; height: 340px; width: 100%; }
      .dashboard .avatar .avatar_bg{ position:absolute; left:50%; margin-left: -100px; top:0; z-index:1; width:200px; }
  
    .dashboard .content{ position:relative; z-index:1; width: 90%; padding-top:120px; }
      .dashboard h1{ margin:10px; font-size: 30px; font-weight: bold; text-align: center; }
    .dashboard .evolucao{ border:1px solid #2BF799; border-radius: 20px; color:white; width:100%; margin:0 auto; box-sizing: border-box; padding:5px 20px; }
    .dashboard .evolucao .line{ display:flex; width:100%; justify-content: space-between; align-items: baseline; }
      .dashboard .evolucao .line .porcentagem{ font-size:24px; font-weight: bold; margin:0; }
      .dashboard .evolucao .line .level{ font-size:12px; margin:0; }
    .dashboard .evolucao .progress-bar{ background-color:#2BF799; }
    .dashboard .evolucao p{ text-align: center; font-size:12px; margin-top:5px; }

    .dashboard .content .exercicio{ width:100%; max-width: 100%; margin:10px 0; }

    .lista_premios{ border-radius:30px; background:#18181C; display: flex; flex-direction: column; margin-top:20px; align-items: center; padding-top:20px; }
      .lista_premios h1{ font-size:22px; }
      .lista_premios div.line{ display: flex; padding:10px 10px; box-sizing: border-box; align-items: center; justify-content: space-between; width:100%; }
        .lista_premios div .img{ height:50px; width:50px; border-radius: 80px; overflow: hidden; min-width: 50px; }
          .lista_premios div .img img{ height:100%; }
          .lista_premios div p{ color: #7F8B8E; font-size: 16px; margin:0 20px; text-transform: uppercase; text-align: left; width:100%; }
          .lista_premios div .moedas{ display: flex; color:white; font-size: 12px; }
            .lista_premios div .moedas .amarela{ background-image:url("./assets/coin_amarela.png"); height: 27px; width: 27px; text-align: center; box-sizing: border-box; padding: 4px 2px 0 0; margin:0 5px; }
            .lista_premios div .moedas .verde{ background-image:url("./assets/coin_verde.png");  height: 27px; width: 27px; text-align: center; box-sizing: border-box; padding: 4px 2px 0 0; margin:0 5px; }

            .lista_premios div .usuarios{ display: flex; color:white; font-size: 12px; width:74px; }


  .ranking{ display:flex; position:relative; align-items: center; flex-direction: column; padding-top:0px; height:100%; overflow-y: auto; box-sizing: border-box; }
  @media only screen and (max-width: 768px) {
    .ranking{ flex-direction: column; height:100%;  flex-wrap: nowrap; }
  }
  @media only screen and (max-width: 400px) {
    .ranking{ flex-direction: column; height:100%;  flex-wrap: nowrap; }
  }

    .ranking .header{ margin-top:10px; width:100%; }
      .ranking .header img{ width:100%; }
      .ranking .meses{ margin:20px 0 0 0; width:90%; }
        .ranking .meses img{ width:100%; }
      .ranking .ranking{ margin-top:10px; width:100%; }
        .ranking .ranking img{ width:100%; }

        .ranking h1{ color: white; box-sizing: border-box; border-radius: 30px; margin: 10px 0; padding: 5px 10px; border:1px solid; border-color: #707070; font-size: 16px; margin:20px 0; }
  .ranking .lista_usuarios{ width:100%; }
      .ranking .lista_usuarios{  padding:0px 10px; box-sizing: border-box;  }
        .ranking .lista_usuarios div{ background:#18181C; display: flex; justify-content: center; justify-content: space-between; align-items: center; margin:5px 0; padding:10px 0; }
        .ranking .lista_usuarios div .avatar{ width:80px; height: 80px; border-radius: 80px; overflow: hidden;}
          .ranking .lista_usuarios div .avatar img{ width:100%; }
        .ranking .lista_usuarios div .info{ display: flex; flex-direction: column; margin:0 20px; justify-content: center; min-width:130px; text-align: left;}
          .ranking .lista_usuarios div .info h2{ font-size: 15px; font-weight: bold; margin:0; width:100%; }
          .ranking .lista_usuarios div .info p{ font-size: 13px; margin:0; width:100%; }
        .ranking .lista_usuarios div .social{ display: flex; margin:0 20px; justify-content: center; align-content: center; }
          .ranking .lista_usuarios div .social img{ margin:20px 5px; }


  .blog{ display:flex; position:relative; align-items: center; flex-direction: column; padding:10px 0 40px 0; height:100%; overflow-y: auto; box-sizing: border-box; }
  @media only screen and (max-width: 768px) {
    .blog{ flex-direction: column; height:100%;  flex-wrap: nowrap; }
  }
  @media only screen and (max-width: 400px) {
    .blog{ flex-direction: column; height:100%;  flex-wrap: nowrap; }
  }

    .blog .header{ margin-top:10px; width:100%; text-align: left; }
      .blog .header img{ width:100%; }
      .blog .noticia{ margin:20px 0; width:90%; }
        .blog .noticia img{ width:100%; }

  .perfil{ display:flex; position:relative; align-items: center; flex-direction: column; padding:40px 0 20px 0; height:100%; overflow-y: auto; box-sizing: border-box; }
  .perfil button{ margin:0 10px; }
      @media only screen and (max-width: 768px) {
        .perfil{ flex-direction: column; height:100%;  flex-wrap: nowrap; }
        .perfil button.logout{ margin:10px 0px; width: 70%; }
        .perfil button.default{ margin:10px 0px; width: 70%; }
      }
      @media only screen and (max-width: 400px) {
        .perfil{ flex-direction: column; height:100%;  flex-wrap: nowrap; }
        .perfil button.logout{ margin:10px 0px; width: 70%; }
        .perfil button.default{ margin:10px 0px; width: 70%; }
      }


      .perfil .header{ margin-top:10px; width:100%; }
        .perfil .header img{ width:100%; }
      .perfil .paginador{ display: flex; align-items:flex-end; justify-content: space-around; margin-bottom:20px; width:90%; max-width: 337px; }
      .perfil .paginador div{ border:2px solid white; border-radius:100px; color:white; display:flex; font-size:25px; 
                              opacity:0.4; padding:4px 17px; height:52px; width: 52px; box-sizing: border-box; position:relative; }
        .perfil .paginador div.ativo{ opacity:1;  }
        .perfil .paginador div.risco::before { content:""; position: absolute; bottom: 0; left: 56px; right: 0; top: 25px; height: 0.5em; width: 100%; border-top: 1px solid white;  z-index: 2; }
  
        .perfil .avatar{ background:url("./assets/avatar_circle.png") no-repeat; background-position: center;  }
        .perfil .avatar div{ margin:0 auto; width:190px; }
        .perfil .avatar .slick-next{ right:-45px; }
        .perfil .avatar .slick-next:before{ content:""; background-image: url("./assets/right.png"); height:34px; width:19px; display:block; }
        .perfil .avatar .slick-prev{ left:-45px; z-index:10; }
        .perfil .avatar .slick-prev:before{ content:""; background-image: url("./assets/left.png"); height:34px; width:19px; display:block; }

        .perfil .content{ width:100%; text-align: center; }
        .perfil h1{ border:2px solid #3450C6; border-radius: 10px; padding:10px 15px; width: 90%; box-sizing: border-box; margin:15px auto; font-size:20px; font-weight: bold; }
        .perfil fieldset{ border:0; margin:0; }
          .perfil fieldset p{ text-align:left; width: 90%; }
        .perfil label{ color:white; text-align: center; width:100%; display: block; font-size:14px; }
        .perfil select{ color:white; border:0; height:50px; width:90%; border-radius: 10px; background:#18181C; margin:5px; text-align: center; padding:0 10px; box-sizing: border-box; }
        .perfil input{ color:white; border:0; height:50px; width:90%; border-radius: 10px; background:#18181C; margin:5px; text-align: center; padding:0 10px; box-sizing: border-box; }
        .perfil button.default{ background:#3450C6; color:white; font-size:21px; border:0; border-radius: 10px; font-weight: 600; padding:10px 60px; margin-top:10px; }


  .card{ background:#393939e8; border-radius: 20px; box-sizing: border-box; padding:20px 15px; width:90%; display: block; }

  
    footer{ background:black; display:flex; align-items: center; justify-content: center; justify-content: space-around; padding:10px 0; position:fixed; bottom:0; left:0; width:100%; z-index:100; }
      footer div{ opacity: 0.3; }
        footer div:hover{ opacity: 1; }
      footer div.ativo{ opacity: 1; }
      

    .header_evolucao{ background:#18181C; width:100%; padding: 10px 20px; box-sizing: border-box; }
      .header_evolucao .line{ display: flex; align-items: center; justify-content: space-around; }
      .header_evolucao .avatar{ display: flex; align-items: center; justify-content: center; }
        .header_evolucao .avatar .img{ border:3px solid #3450C6; border-radius: 50px; height:50px; width: 50px; overflow: hidden; margin:10px 0px; }
          .header_evolucao .avatar .img img{ width:100%; }
          
        .header_evolucao .avatar .greetings{ color:white; font-size:12px; margin:0 10px; line-height: 12px; }
          .header_evolucao .avatar .greetings span{ font-weight: bold; }

        .header_evolucao p{ font-size: 12px; text-align: center; }
        .header_evolucao .progress-bar{ background-color:#2BF799; }

        
        .header_evolucao .coins{ display: flex; color:white; font-size: 12px; flex-direction: column; }
          .header_evolucao .coins p{ margin:0; }
            .header_evolucao .coins .flex{ display:flex; }
          .header_evolucao .coins .amarela{ background-image:url("./assets/coin_amarela.png"); height: 27px; width: 27px; text-align: center; box-sizing: border-box; padding: 4px 2px 0 0; }
          .header_evolucao .coins .verde{ background-image:url("./assets/coin_verde.png");  height: 27px; width: 27px; text-align: center; box-sizing: border-box; padding: 4px 2px 0 0; }
        
        .header_evolucao .level{ color:white; }
          .header_evolucao .level span{ font-size: 30px; }

.treinos{ display:flex; position:relative;  height:100%; overflow-y: auto; box-sizing: border-box; width: 100%; align-items: center;  flex-direction: column; padding-top:10px; }
      .treinos .linha{ display:flex; flex-direction: row; flex-wrap: wrap; width: 100%; align-items: right; justify-content: center; }
        .treinos .linha.direita{  justify-content: center; }
    .treinos .header{ margin:0px; width:100%; }
    .treinos .linha .filtra_estilos{ background:black; color:white; box-sizing: border-box; border-radius: 30px; margin:10px 0;
                                     padding: 5px 10px; margin-right:10px; border-color:#707070; -webkit-appearance: none; -moz-appearance: none; appearance: none; box-sizing: border-box; 
                                    }
.treinos button{ font-size: 14px; padding: 5px 20px; background: #646464; }

.exercicio{ background:#18181C; border-radius:10px; margin:10px; width:90%; padding: 0; box-sizing: border-box; max-width: 385px; position:relative; }
  .exercicio h2{ font-weight: bold; font-size:23px; margin-left:8px; width:75%; }
  .exercicio .features{ color:white; width: 70%; display: flex; flex-wrap: wrap; }
    .exercicio .features div{ display:flex;  margin: 0 10px; }
      .exercicio .features div p{ margin:0; }
      .exercicio .features div svg{ color:white; margin:3px 8px 0 0; }

.exercicio .percentage{ position:absolute; right:17px; bottom:50%; margin-bottom:-25px; border:1px solid #3450C6; border-radius:100px; padding:12px 10px 0 10px; box-sizing: border-box; height: 50px; width: 50px; text-align: center;  }

.exercicio .header{ padding:10px 10px; cursor: pointer; position: relative; }
.exercicio .info{ margin-left:20px; }

.exercicio .body{ height:200px; overflow:hidden; position:relative; }
  .exercicio .body .play{ position:absolute; left:50%; margin-left: -41px; top:60px; z-index:10; cursor: pointer; }
  .exercicio .body .cover{ opacity:0.48; width:100%; }


  .exercicio_realizado{ position:relative; height:100%; overflow-y: auto; box-sizing: border-box; text-align: center; }
    .exercicio_realizado .blog{ height:auto; }
    .exercicio_realizado .back{ width:80%; margin:10px auto; text-align:left; margin-left:20px; height:30px; }
      .exercicio_realizado .back a p{ color:grey; text-decoration: none; font-weight: bold; font-size:22px; line-height: 0; float:left; margin-top:11px;  }
      .exercicio_realizado .back svg{ color:#3450C6; margin:0 12px 0 0; font-size: 24px; float:left; }
      .exercicio_realizado h1{ text-align: center; margin-top:40px; font-weight: bold; }
      .exercicio_realizado .pontos{ color:#2BF799; font-size:25px;font-weight: bold; }
      .exercicio_realizado .avaliar_treino{ border:1px solid #3450C6; border-radius:20px; width:90%; margin:0 auto; padding:20px 0px; box-sizing: border-box; }
      .exercicio_realizado .avaliar_treino img{ opacity: 0.4; margin:0 10px; }
      .exercicio_realizado .avaliar_treino img:hover{ opacity: 1; margin:0 10px; }

      .historico_treinos{ position:relative; height:100%; overflow-y: auto; box-sizing: border-box; text-align: center; }
      .historico_treinos .back{ width:80%; margin:10px auto; text-align:left; margin-left:20px; height:30px; }
        .historico_treinos .back a p{ color:grey; text-decoration: none; font-weight: bold; font-size:22px; line-height: 0; float:left; margin-top:11px;  }
        .historico_treinos .back svg{ color:#3450C6; margin:0 12px 0 0; font-size: 24px; float:left; }

        .historico_treinos .exercicios{ display: flex; flex-direction: row; align-items: start; flex-wrap: wrap; justify-content: center; }
        .historico_treinos .exercicio{ background:#18181C; border-radius:10px; margin:10px; width:90%; min-width: 300px; padding: 20px 0; box-sizing: border-box; max-width: 385px; position:relative; text-align: center; }
        .historico_treinos .exercicio h2{ padding: 0 0 10px 0; width:100%; }
        .historico_treinos .exercicio .info{ float:left; width:40%; min-width:147px; }
          .historico_treinos .exercicio .info .cover{ border-radius:20px; width:100%; }
          
          @media only screen and (max-width: 320px) {
            .historico_treinos .exercicio .info{ width:90%; margin: 10px auto; float:none; }
          }
        
        .historico_treinos .exercicio_realizado h1{ text-align: center; margin-top:40px; font-weight: bold; }
        .historico_treinos .exercicio_realizado .pontos{ color:#2BF799; font-size:25px;font-weight: bold; }
        .historico_treinos .exercicio_realizado .avaliar_treino{ border:1px solid #3450C6; border-radius:20px; width:90%; margin:0 auto; padding:20px 0px; box-sizing: border-box; }
        .historico_treinos .exercicio_realizado .avaliar_treino img{ opacity: 0.4; margin:0 10px; }
        .historico_treinos .exercicio_realizado .avaliar_treino img:hover{ opacity: 1; margin:0 10px; }

  .level_up{ background:url('./assets/level_up.png') no-repeat; background-position: center 60px; padding-top:40px; box-sizing: border-box; }
    .level_up .avatar_bg{ width:300px; }
    .level_up h1{ font-size:40px; line-height: 35px; margin-top:15px;  }
      .level_up h1 span{ color:#2BF799; }

.modal-content{ background:black !important; width: 100% !important; }
    .modal-content .features{ color:white; display:flex; margin-top:10px; }
    .modal-content .features div{ display:flex;  margin: 0 10px; }
      .modal-content .features div p{ margin:0; }
      .modal-content .features div svg{ color:white; margin:3px 8px 0 0; }
.modal-dialog{ width: 100%; max-width: 100% !important;}

.noticia{ background:#18181C; position:relative; }
  .noticia .imagem{ border-top-left-radius: 10px; border-top-right-radius: 10px; overflow: hidden; }
    .noticia .imagem img{ width:100%; }
  .noticia .header{ border-radius:20px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; overflow: hidden; padding:10px 15px; box-sizing: border-box; padding-bottom:30px; }
    .noticia .header h2{ font-size:25px; font-weight: bold; margin:0; }
    .noticia .header h3{ font-size:18px; font-weight: bold; margin:5px 0; }
    .noticia .header .date{ float:right; }
    .noticia button{ background:#3450C6; color:white; border-radius: 10px; border:none; padding:10px 20px; position:absolute; bottom:-22px; left:50%; margin-left:-55px; 
                    text-transform: uppercase; font-weight: bold; }

.noticia_aberta{ position:relative; height:100%; overflow-y: auto; box-sizing: border-box; text-align: center; }


  .noticia_aberta .imagem{ border-top-left-radius: 10px; border-top-right-radius: 10px; overflow: hidden; margin-top:10px; }
    .noticia_aberta .imagem img{ width:100%; }
  .noticia_aberta .content{ padding:10px 10px; }
    .noticia_aberta .content p{ color:white; }

    .premio_aberto{ position:relative; height:100%; overflow-y: auto; box-sizing: border-box; text-align: center; }

    .premio_aberto .imagem{ border-top-left-radius: 10px; border-top-right-radius: 10px; overflow: hidden; margin-top:10px; }
    .premio_aberto .imagem img{ margin-bottom:10px; width:100%; }
  .premio_aberto .content{ padding:10px 10px; }
    .premio_aberto .content p{ color:white; }
    .premio_aberto .content h2{ font-size:16px; color:#2BF799; }

    

.anamnese{ background:url("./assets/bg_cadastro.jpg") no-repeat; background-size: cover; background-position: center;
           position:relative; height:100%; overflow-y: auto; box-sizing: border-box; text-align: center; padding:10px 20px; }
  
.anamnese h1{ border:2px solid #3450C6; border-radius: 10px; padding:10px 15px; width: 90%; box-sizing: border-box; margin:15px auto; font-size:20px; font-weight: bold; }
  .anamnese p{ color:white; }
  .anamnese .form{ padding:20px 0; }
    .anamnese .form label{ color: white; width:40%; }
    .anamnese .form input{ color:white; border:0; height:50px; width:100px; border-radius: 10px; background:#18181C; margin:5px; text-align: center; padding:0 10px; box-sizing: border-box; }